import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  useToast,
} from '@chakra-ui/react';

const AddCategory = ({ isOpen, onClose, addNewCategory }) => {
  const [newCategory, setNewCategory] = useState({
    name: '',
    city: '',
    status: '',  // Added status field
  });
  const [imageFile, setImageFile] = useState(null);  // To store the uploaded image

  const toast = useToast();

  // Handle input change for name, city, and status
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCategory({ ...newCategory, [name]: value });
  };

  // Handle file change for image upload
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
  };

  // Handle form submission
  const handleSubmit = () => {
    if (newCategory.name && newCategory.city && newCategory.status && imageFile) {
      // Prepare form data to upload category with image
      const formData = new FormData();
      formData.append('name', newCategory.name);
      formData.append('position', newCategory.position);
      formData.append('city', newCategory.city);
      formData.append('status', newCategory.status);  // Append the status
      formData.append('file', imageFile);  // Append the image file

      addNewCategory(formData);
      setNewCategory({
        name: '',
        position:'',
        city: '',
        status: '',
      });
      setImageFile(null);
      onClose();
    } else {
      toast({
        title: 'Error.',
        description: 'Please fill out all fields and upload an image.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Category</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Category Name */}
          <FormControl id="name" mb={3}>
            <FormLabel>Category Name</FormLabel>
            <Input
              type="text"
              name="name"
              value={newCategory.name}
              onChange={handleInputChange}
              placeholder="Enter category name"
            />
          </FormControl>

          <FormControl id="position" mb={3}>
            <FormLabel>Position</FormLabel>
            <Input
              type="number"
              name="position"
              value={newCategory.position}
              onChange={handleInputChange}
              placeholder="Enter position"
            />
          </FormControl>

          {/* City Dropdown */}
          <FormControl id="city" mb={3}>
            <FormLabel>City</FormLabel>
            <Select
              name="city"
              value={newCategory.city}
              onChange={handleInputChange}
              placeholder="Select city"
            >
              <option value="Bengaluru">Bengaluru</option>
              <option value="Mysore">Mysore</option>
            </Select>
          </FormControl>

          {/* Status Dropdown */}
          <FormControl id="status" mb={3}>
            <FormLabel>Status</FormLabel>
            <Select
              name="status"
              value={newCategory.status}
              onChange={handleInputChange}
              placeholder="Select status"
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Select>
          </FormControl>

          {/* Image Upload */}
          <FormControl id="imageFile" mb={3}>
            <FormLabel>Category Image</FormLabel>
            <Input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              placeholder="Upload category image"
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddCategory;
