import React from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Button, Text } from "@chakra-ui/react";

const DeleteRoute = ({ isOpen, onClose, deleteRoute, routeData }) => {
  const handleDelete = () => {
    deleteRoute(routeData.id);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Route</ModalHeader>
        <ModalBody>
          <Text>Are you sure you want to delete the route: {routeData.city}?</Text>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="red" onClick={handleDelete}>
            Delete Route
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteRoute;
