import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  RadioGroup,
  Radio,
  Stack,
  HStack, // Added to handle horizontal layout
  Button,
  useToast,
} from '@chakra-ui/react';
import { getCategoriesApi } from 'networks'; // Import your API call
import { position } from 'stylis';

const EditProduct = ({ isOpen, onClose, updateProductProp, updateProduct }) => {
  const [product, setProduct] = useState({
    id: '',
    product_code: '', // Changed productCode to product_code
    name: '',
    position:'',
    category_id: '', // Use category_id for category selection
    price: '',
    city: '',
    cgst: '',
    sgst: '',
    discount: '',
    weight: '', // New weight field
    weight_unit: 'gm', // New unit field for weight
    description: '',
    subscription: 'Both',
    status: '',
  });

  const [categories, setCategories] = useState([]); // State for categories
  const toast = useToast();

  useEffect(() => {
    // Fetch categories when the component loads
    const fetchCategories = async () => {
      try {
        const response = await getCategoriesApi();
        setCategories(response.data.data); // Assuming API returns data in response.data
      } catch (error) {
        toast({
          title: 'Error fetching categories.',
          description: 'An error occurred while fetching categories.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    if (updateProductProp) {
      setProduct({
        id: updateProductProp.id || '',
        product_code: updateProductProp.product_code || '',
        name: updateProductProp.name || '',
        position:updateProductProp.position || '',
        category_id: updateProductProp.category_id || '',
        price: updateProductProp.price || '',
        city: updateProductProp.city || '',
        cgst: updateProductProp.cgst || '',
        sgst: updateProductProp.sgst || '',
        discount: updateProductProp.discount || '',
        weight: updateProductProp.weight || '',
        weight_unit: updateProductProp.weight_unit || 'gm', // Set weight unit from updateProductProp
        description: updateProductProp.description || '',
        subscription: updateProductProp.subscription || 'Both',
        status: updateProductProp.status || '',
      });
    }
  }, [updateProductProp]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const handleSubmit = () => {
    if (
      product.product_code &&
      product.name &&
      product.position &&
      product.category_id &&
      product.price &&
      product.city &&
      product.cgst &&
      product.sgst &&
      product.discount &&
      product.weight &&
      product.description &&
      product.status
    ) {
      const formData = {
        id: product.id,
        product_code: product.product_code, // Send product_code instead of productCode
        name: product.name,
        position:product.position,
        category_id: product.category_id,
        price: product.price,
        city: product.city,
        cgst: product.cgst,
        sgst: product.sgst,
        discount: product.discount,
        weight: product.weight,
        weight_unit: product.weight_unit, // Include weight unit in form data
        description: product.description,
        subscription: product.subscription,
        status: product.status,
      };
      updateProduct(formData);
      onClose();
    } else {
      toast({
        title: 'Error.',
        description: 'Please fill out all fields.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Product</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Product Code */}
          <FormControl id="product_code" mb={3}>
            <FormLabel>Product Code</FormLabel>
            <Input
              type="text"
              name="product_code"
              value={product.product_code}
              onChange={handleInputChange}
              placeholder="Enter product code"
            />
          </FormControl>

          {/* Product Name */}
          <FormControl id="name" mb={3}>
            <FormLabel>Product Name</FormLabel>
            <Input
              type="text"
              name="name"
              value={product.name}
              onChange={handleInputChange}
              placeholder="Enter product name"
            />
          </FormControl>

          <FormControl id="position" mb={3}>
            <FormLabel>Position</FormLabel>
            <Input
              type="number"
              name="position"
              value={product.position}
              onChange={handleInputChange}
              placeholder="Enter position"
            />
          </FormControl>

          {/* Category */}
          <FormControl id="category_id" mb={3}>
            <FormLabel>Category</FormLabel>
            <Select
              name="category_id"
              value={product.category_id}
              onChange={handleInputChange}
              placeholder="Select category"
            >
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </Select>
          </FormControl>

          {/* Price */}
          <FormControl id="price" mb={3}>
            <FormLabel>Price</FormLabel>
            <Input
              type="number"
              name="price"
              value={product.price}
              onChange={handleInputChange}
              placeholder="Enter price"
            />
          </FormControl>

          {/* City */}
          <FormControl id="city" mb={3}>
            <FormLabel>City</FormLabel>
            <Select
              name="city"
              value={product.city}
              onChange={handleInputChange}
              placeholder="Select city"
            >
              <option value="Bengaluru">Bengaluru</option>
              <option value="Mysore">Mysore</option>
            </Select>
          </FormControl>

          {/* CGST */}
          <FormControl id="cgst" mb={3}>
            <FormLabel>CGST</FormLabel>
            <Input
              type="number"
              name="cgst"
              value={product.cgst}
              onChange={handleInputChange}
              placeholder="Enter CGST percentage"
            />
          </FormControl>

          {/* SGST */}
          <FormControl id="sgst" mb={3}>
            <FormLabel>SGST</FormLabel>
            <Input
              type="number"
              name="sgst"
              value={product.sgst}
              onChange={handleInputChange}
              placeholder="Enter SGST percentage"
            />
          </FormControl>

          {/* Discount */}
          <FormControl id="discount" mb={3}>
            <FormLabel>Discount</FormLabel>
            <Input
              type="number"
              name="discount"
              value={product.discount}
              onChange={handleInputChange}
              placeholder="Enter discount percentage"
            />
          </FormControl>

          {/* Weight with Unit */}
          <FormControl id="weight" mb={3}>
            <FormLabel>Weight</FormLabel>
            <HStack>
              <Input
                type="text"
                name="weight"
                value={product.weight}
                onChange={handleInputChange}
                placeholder="Enter product weight"
              />
              <Select
                name="weight_unit"
                value={product.weight_unit}
                onChange={handleInputChange}
              >
                <option value="gm">gm</option>
                <option value="ml">ml</option>
              </Select>
            </HStack>
          </FormControl>

          {/* Description */}
          <FormControl id="description" mb={3}>
            <FormLabel>Description</FormLabel>
            <Textarea
              name="description"
              value={product.description}
              onChange={handleInputChange}
              placeholder="Enter product description"
            />
          </FormControl>

          {/* Subscription */}
          <FormControl as="fieldset" mb={3}>
            <FormLabel as="legend">Subscription</FormLabel>
            <RadioGroup
              name="subscription"
              value={product.subscription}
              onChange={(value) => setProduct({ ...product, subscription: value })}
            >
              <Stack direction="row">
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
                <Radio value="Both">Both</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>

          {/* Status */}
          <FormControl id="status" mb={3}>
            <FormLabel>Status</FormLabel>
            <Select
              name="status"
              value={product.status}
              onChange={handleInputChange}
              placeholder="Select status"
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Select>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditProduct;
