import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Text,
  useColorModeValue,
  IconButton,
  Spinner,
  useToast,
  Card,
  TableContainer,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { MdAdd, MdEdit, MdDelete } from "react-icons/md"; // Keep edit and delete icons for now
import { SearchIcon } from "@chakra-ui/icons";

const RouteManagement = () => {
  const [routes, setRoutes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const toast = useToast();

  useEffect(() => {
    fetchRoutesData();
  }, []);

  const fetchRoutesData = async () => {
    setLoading(true);
    try {
      setRoutes([
        {
          id: 1,
          route_name: "Route 1",
          route_code: "560001",
          delivery_area: "Bangalore, Karnataka",
          delivery_boy_count: 5,
          assigned_delivery_boys: ["Rahul Sharma", "Manu Patel"],
          status: "Active",
          image_url: "https://via.placeholder.com/150",
        },
        {
          id: 2,
          route_name: "Route 2",
          route_code: "560002",
          delivery_area: "Mysore, Karnataka",
          delivery_boy_count: 3,
          assigned_delivery_boys: ["Amit Verma", "Ravi Kumar"],
          status: "Inactive",
          image_url: "https://via.placeholder.com/150",
        },
      ]);
    } catch (error) {
      toast({
        title: "Error fetching routes.",
        description: "An error occurred while fetching routes.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const filteredRoutes = routes.filter(
    (route) =>
      route.route_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      route.status.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box pt={{ base: "130px", md: "20px", xl: "35px" }} overflowY="auto">
      <Flex flexDirection="column">
        <Flex
          mt={{ base: "20px", md: "45px" }}
          mb="20px"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text
            color={textColor}
            fontSize={{ base: "lg", md: "2xl" }}
            ms="24px"
            fontWeight="700"
          >
            Route Management
          </Text>
          <Button
            leftIcon={<MdAdd />}
            bg="#FFFFFF"
            color="#333"
            _hover={{ bg: "#D3D3D3" }}
            ml={4}
          >
            Add Route
          </Button>
        </Flex>

        <Flex justify="flex-end" mt={4} mr={4}>
          <InputGroup>
            <Input
              type="text"
              placeholder="Search by route name or status..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              backgroundColor="white"
              size="md"
            />
            <InputRightElement>
              <IconButton
                aria-label="Search"
                icon={<SearchIcon />}
                onClick={() => setSearchTerm("")}
                variant="ghost"
                size="md"
              />
            </InputRightElement>
          </InputGroup>
        </Flex>

        <Flex justifyContent="center" mt={10}>
          {loading ? (
            <Flex justify="center" align="center" height="10vh">
              <Spinner size="lg" />
            </Flex>
          ) : (
            <Card width="95%" borderRadius={20}>
              <TableContainer>
                <Table variant="simple" size="md">
                  <Thead>
                    <Tr>
                      <Th>Sl No</Th>
                      <Th>Route Name</Th>
                      <Th>Route Code</Th>
                      <Th>Delivery Area</Th>
                      <Th>Delivery Boy Count</Th>
                      <Th>Status</Th>
                      <Th>Assigned Delivery Boys</Th>
                      <Th>Image</Th>
                      <Th>Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {filteredRoutes.map((route, index) => (
                      <Tr key={route.id}>
                        <Td>{index + 1}</Td>
                        <Td>{route.route_name}</Td>
                        <Td>{route.route_code}</Td>
                        <Td>{route.delivery_area}</Td>
                        <Td>{route.delivery_boy_count}</Td>
                        <Td color={route.status === "Active" ? "green" : "red"}>
                          {route.status}
                        </Td>
                        <Td>
                          <Flex direction="column">
                            {route.assigned_delivery_boys.map((deliveryBoy, i) => (
                              <Text key={i}>{deliveryBoy}</Text>
                            ))}
                          </Flex>
                        </Td>
                        <Td>
                          <img
                            src={route.image_url}
                            alt={route.route_name}
                            style={{ width: "50px", height: "50px", objectFit: "cover" }}
                          />
                        </Td>
                        <Td>
                          <IconButton
                            icon={<MdEdit />}
                            colorScheme="blue"
                            variant="ghost"
                          />
                          <IconButton
                            icon={<MdDelete />}
                            colorScheme="red"
                            variant="ghost"
                            ml={3}
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Card>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default RouteManagement;
