// SubscriptionManagement.js
import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Spinner,
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Input,
  Button,
  HStack,
  useToast,
} from "@chakra-ui/react";
import { MdEdit, MdDelete, MdVisibility, MdMap } from "react-icons/md";
import { getSubscriptionsApi, updateSubscriptionApi } from "networks";
import { addDays, subDays, format } from "date-fns";
import EditSubscription from "./EditSubscription";

const SubscriptionManagement = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDate, setSelectedDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [dates, setDates] = useState([]);
  const [editSubscriptionData, setEditSubscriptionData] = useState(null);
  const toast = useToast();
  useEffect(() => {
    fetchSubscriptions(selectedDate);
    generateDateRange();
  }, []);

  const fetchSubscriptions = async (date) => {
    setLoading(true);
    try {
      const response = await getSubscriptionsApi({ date });
      if (response.status === 200 && response.data.status === "success") {
        const fetchedSubscriptions = response.data.data;
        setSubscriptions(fetchedSubscriptions);
        setFilteredSubscriptions(fetchedSubscriptions);
      } else {
        setSubscriptions([]);
        setFilteredSubscriptions([]);
      }
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
    } finally {
      setLoading(false);
    }
  };

  const generateDateRange = () => {
    const today = new Date();
    const start = subDays(today, 3);
    const end = addDays(today, 5);
    const dateArray = [];

    for (let d = start; d <= end; d = addDays(d, 1)) {
      dateArray.push(format(d, "yyyy-MM-dd"));
    }

    setDates(dateArray);
  };

  const formatSubscriptionDate = (timestamp) => {
    if (!timestamp) return "N/A";
    const date = new Date(timestamp);
    return format(date, "yyyy-MM-dd");
  };

  useEffect(() => {
    let filtered = subscriptions;
    filtered = filtered.filter((subscription) => subscription.paused === "No" && subscription.quantity > 0);

    if (searchTerm) {
      filtered = filtered.filter(
        (subscription) =>
          subscription.user_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          subscription.phone_number.includes(searchTerm)
      );
    }

    setFilteredSubscriptions(filtered);
  }, [selectedDate, searchTerm, subscriptions]);

  const handleDateClick = (date) => {
    setSelectedDate(date);
    fetchSubscriptions(date);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const isToday = (date) => format(new Date(), "yyyy-MM-dd") === date;

  const handleEditSubscription = (subscription) => {
    setEditSubscriptionData(subscription);
  };

  const handleUpdateSubscription = async (updatedSubscription) => {
    try {
      const response=await updateSubscriptionApi(updatedSubscription);
      console.log(response.data.message)
      fetchSubscriptions(selectedDate);
      setEditSubscriptionData(null);
    } catch (error) {
      toast({
        title: 'Error.',
        description:  error.response.data.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleViewMap = (order) => {
    const { latitude, longitude } = order; 
    const mapUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(mapUrl, '_blank'); // Open Google Maps in a new window
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Placed":
        return "blue.500"; // Blue for Placed
      case "Accepted":
        return "orange.500"; // Orange for Accepted
      case "Delivering":
        return "yellow.500"; // Yellow for Delivering
      case "Delivered":
        return "green.500"; // Green for Delivered
      case "Pending":
        return "gray.500"; // Gray for Pending
      default:
        return "gray.500"; // Default color
    }
  };
  return (
    <Box pt={{ base: "130px", md: "20px", xl: "35px" }} overflowY={{ sm: "scroll", lg: "hidden" }}>
      <Flex flexDirection="column">
        <Flex mt="45px" mb="20px" justifyContent="space-between" align={{ base: "start", md: "center" }}>
          <Text fontSize="2xl" ms="24px" fontWeight="700">
            Subscription Management
          </Text>
        </Flex>

        <HStack overflowX="scroll" p={4} spacing={4}>
          {dates.map((date, index) => (
            <Button
              key={index}
              onClick={() => handleDateClick(date)}
              backgroundColor={selectedDate === date ? "teal" : "gray.200"}
              color={selectedDate === date ? "white" : "black"}
            >
              {isToday(date) ? "Today" : format(new Date(date), "EEE, MMM d")}
            </Button>
          ))}
        </HStack>

        <Flex justifyContent={"center"} mb={5}>
          <Input
            placeholder="Search by User Name or Phone"
            value={searchTerm}
            onChange={handleSearchChange}
            width="40%"
            mr={3}
            backgroundColor={"white"}
          />
        </Flex>

        <Flex justifyContent={"center"} mt={30}>
          <Card width={"97%"} borderRadius={40} bg={"#ededed"}>
            <Box p={5}>
              {loading ? (
                <Flex justify="center" align="center" height="100vh">
                  <Spinner size="xl" />
                </Flex>
              ) : filteredSubscriptions && filteredSubscriptions.length > 0 ? (
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Subscription ID</Th>
                      <Th>User Name</Th>
                      <Th>Phone Number</Th>
                      <Th>Product Name</Th>
                      <Th>Quantity</Th>
                      <Th>Amount</Th>
                      <Th>Start Date</Th>
                      <Th>End Date</Th>
                      <Th>Delivery Slot</Th>
                      
                      <Th>Status</Th>
                      <Th>Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {filteredSubscriptions.map((subscription, index) => (
                      <Tr key={index}>
                        <Td>{subscription.id}</Td>
                        <Td>{subscription.user_name}</Td>
                        <Td>{subscription.phone_number}</Td>
                        <Td>{subscription.product_name}</Td>
                        <Td>{subscription.quantity}</Td>
                        <Td>Rs {subscription.total}</Td>
                        <Td>{formatSubscriptionDate(subscription.start_date)}</Td>
                        <Td>{formatSubscriptionDate(subscription.end_date)}</Td>
                        <Td>{subscription.delivery_slot}</Td>
                        <Td color={getStatusColor(subscription.subscription_status)}>{subscription.subscription_status}</Td>
                        <Td>
                          <Flex gap="1px">
                            <IconButton
                              icon={<MdVisibility />}
                              aria-label="View Subscription"
                              onClick={() => console.log("View subscription", subscription)}
                            />
                            <IconButton
                              icon={<MdEdit />}
                              aria-label="Edit Subscription"
                              onClick={() => handleEditSubscription(subscription)} // Open edit modal
                            />
                            <IconButton
                              icon={<MdDelete />}
                              aria-label="Delete Subscription"
                            />
                            <IconButton
                              icon={<MdMap />} // Add the map icon
                              onClick={() => handleViewMap(subscription)} // Open map when clicked
                              aria-label="View Map"
                            />
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              ) : (
                <Text>No subscriptions available for {selectedDate ? selectedDate : "this date"}</Text>
              )}
            </Box>
          </Card>
        </Flex>
      </Flex>

      {/* EditSubscription modal */}
      {editSubscriptionData && (
        <EditSubscription
          isOpen={!!editSubscriptionData}
          onClose={() => setEditSubscriptionData(null)}
          subscriptionData={editSubscriptionData} // Pass the subscription data to the modal
          updateSubscription={handleUpdateSubscription} // Pass the update function
          selectedDate={selectedDate} // Pass the selected date
        />
      )}
    </Box>
  );
};

export default SubscriptionManagement;
