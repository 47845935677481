import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Text,
  useColorModeValue,
  IconButton,
  Spinner,
  useToast,
  Card,
  useDisclosure,
  TableContainer,
  Input,
  InputGroup,
  InputRightElement,
  Image,
} from "@chakra-ui/react";
import { MdAdd, MdEdit, MdDelete } from "react-icons/md";
import { SearchIcon } from "@chakra-ui/icons";
import AddRoute from "./AddRoute";
import EditRoute from "./EditRoute";
import DeleteRoute from "./DeleteRoute";

const RouteManagement = () => {
  const [routes, setRoutes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editRouteData, setEditRouteData] = useState(null);
  const [deleteRouteData, setDeleteRouteData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const addRouteModal = useDisclosure();
  const toast = useToast();

  useEffect(() => {
    fetchRoutesData();
  }, []);

  // Fetch routes (dummy data in this case)
  const fetchRoutesData = () => {
    setLoading(true);
    setRoutes([
      { id: 1, city: "Bangalore", pincodes: [560001, 560002], status: "Active" },
      { id: 2, city: "Bangalore", pincodes: [560003, 560004], status: "Inactive" },
    ]);
    setLoading(false);
  };

  // Add a new route
  const handleAddRoute = (newRoute) => {
    setRoutes([...routes, newRoute]);
    toast({
      title: "Route added.",
      description: "The route has been added successfully.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    addRouteModal.onClose();
  };

  // Update an existing route
  const handleUpdateRoute = (updatedRoute) => {
    setRoutes(
      routes.map((route) =>
        route.id === updatedRoute.id ? updatedRoute : route
      )
    );
    toast({
      title: "Route updated.",
      description: "The route has been updated successfully.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    setEditRouteData(null);
  };

  // Delete a route
  const handleDeleteRoute = (id) => {
    setRoutes(routes.filter((route) => route.id !== id));
    toast({
      title: "Route deleted.",
      description: "The route has been deleted successfully.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    setDeleteRouteData(null);
  };

  const filteredRoutes = routes.filter(
    (route) =>
      route.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
      String(route.status).includes(searchTerm)
  );

  return (
    <Box pt={{ base: "130px", md: "20px", xl: "35px" }} overflowY="auto">
      <Flex flexDirection="column">
        <Flex
          mt={{ base: "20px", md: "45px" }}
          mb="20px"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text color={textColor} fontSize={{ base: "lg", md: "2xl" }} ms="24px" fontWeight="700">
            Route Management
          </Text>
          <Button
            leftIcon={<MdAdd />}
            bg="#FFFFFF"
            color="#333"
            _hover={{ bg: "#D3D3D3" }}
            onClick={addRouteModal.onOpen}
          >
            Add Route
          </Button>
        </Flex>

        {/* Search Bar */}
        <Flex justify="flex-end" mt={4} mr={4}>
          <InputGroup>
            <Input
              type="text"
              placeholder="Search by city or status..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              backgroundColor="white"
              size="md"
            />
            <InputRightElement>
              <IconButton
                aria-label="Search"
                icon={<SearchIcon />}
                onClick={() => setSearchTerm("")}
                variant="ghost"
                size="md"
              />
            </InputRightElement>
          </InputGroup>
        </Flex>

        {/* Route Table */}
        <Flex justifyContent="center" mt={10}>
          {loading ? (
            <Flex justify="center" align="center" height="10vh">
              <Spinner size="lg" />
            </Flex>
          ) : (
            <Card width="95%" borderRadius={20}>
              <TableContainer>
                <Table variant="simple" size="md">
                  <Thead>
                    <Tr>
                      <Th>ID</Th>
                      <Th>City</Th>
                      <Th>Pincodes</Th>
                      <Th>Status</Th>
                      <Th>Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {filteredRoutes.map((route) => (
                      <Tr key={route.id}>
                        <Td>{route.id}</Td>
                        <Td>{route.city}</Td>
                        <Td>{route.pincodes.join(", ")}</Td>
                        <Td color={route.status === "Active" ? "green.500" : "red.500"}>
                          {route.status}
                        </Td>
                        <Td>
                          <IconButton
                            icon={<MdEdit />}
                            onClick={() => setEditRouteData(route)}
                            aria-label="Edit Route"
                            mr={2}
                            colorScheme="teal"
                            size="sm"
                          />
                          <IconButton
                            icon={<MdDelete />}
                            onClick={() => setDeleteRouteData(route)}
                            aria-label="Delete Route"
                            colorScheme="red"
                            size="sm"
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Card>
          )}
        </Flex>
      </Flex>

      {/* AddRoute modal */}
      <AddRoute isOpen={addRouteModal.isOpen} onClose={addRouteModal.onClose} addNewRoute={handleAddRoute} />

      {/* EditRoute modal */}
      {editRouteData && (
        <EditRoute
          isOpen={!!editRouteData}
          onClose={() => setEditRouteData(null)}
          updateRoute={handleUpdateRoute}
          routeData={editRouteData}
        />
      )}

      {/* DeleteRoute modal */}
      {deleteRouteData && (
        <DeleteRoute
          isOpen={!!deleteRouteData}
          onClose={() => setDeleteRouteData(null)}
          deleteRoute={handleDeleteRoute}
          routeData={deleteRouteData}
        />
      )}
    </Box>
  );
};

export default RouteManagement;
