import React, { useState, useEffect } from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Button, FormControl, FormLabel, Input, useToast } from "@chakra-ui/react";

const EditRoute = ({ isOpen, onClose, updateRoute, routeData }) => {
  const [city, setCity] = useState(routeData.city);
  const [pincodes, setPincodes] = useState(routeData.pincodes.join(", "));
  const [status, setStatus] = useState(routeData.status);
  const toast = useToast();

  const handleUpdate = () => {
    if (!city || !pincodes) {
      toast({
        title: "Missing fields.",
        description: "Please fill all the fields.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const updatedRoute = {
      ...routeData,
      city,
      pincodes: pincodes.split(",").map((p) => p.trim()),
      status,
    };
    updateRoute(updatedRoute);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Route</ModalHeader>
        <ModalBody>
          <FormControl>
            <FormLabel>City</FormLabel>
            <Input value={city} onChange={(e) => setCity(e.target.value)} />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Pincodes</FormLabel>
            <Input value={pincodes} onChange={(e) => setPincodes(e.target.value)} />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Status</FormLabel>
            <Input value={status} onChange={(e) => setStatus(e.target.value)} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleUpdate}>
            Update Route
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditRoute;
