import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import ReactSelect from "react-select"; // Import react-select for multi-select

import { getPincodesApi } from "../../networks"; // Ensure correct import path

const AddRoute = ({ isOpen, onClose, addNewRoute }) => {
  const [city, setCity] = useState("");
  const [pincodes, setPincodes] = useState([]);
  const [selectedPincodes, setSelectedPincodes] = useState([]); // Array for multiple pincodes
  const [status, setStatus] = useState("Active");
  const toast = useToast();

  // Fetch pincodes from the API
  useEffect(() => {
    const fetchPincodes = async () => {
      try {
        const response = await getPincodesApi();
        console.log("API Response:", response);  // Debugging the API response

        // Check if the response contains valid data
        if (response.data.status === "success" && Array.isArray(response.data.data)) {
          const pincodeList = response.data.data.map((item) => ({
            label: item.pincode, // Label for react-select
            value: item.pincode, // Value for react-select
          }));
          setPincodes(pincodeList); // Set the pincodes array
        } else {
          toast({
            title: "Error fetching pincodes.",
            description: "Pincodes data is not in the correct format.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: "Error fetching pincodes.",
          description: "Unable to fetch pincodes at this time.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        console.error("Error fetching pincodes:", error);  // Log the error
      }
    };

    fetchPincodes();
  }, [toast]);

  const handleAdd = () => {
    if (!city || selectedPincodes.length === 0) {
      toast({
        title: "Missing fields.",
        description: "Please fill all the fields.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const newRoute = {
      id: new Date().getTime(),
      city,
      pincodes: selectedPincodes.map((p) => p.value), // Collecting the values of selected pincodes
      status,
    };
    addNewRoute(newRoute);
    setCity("");
    setSelectedPincodes([]); // Reset selection
    setStatus("Active");
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Route</ModalHeader>
        <ModalBody>
          <FormControl>
            <FormLabel>City</FormLabel>
            <Input value={city} onChange={(e) => setCity(e.target.value)} placeholder="City Name" />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Pincodes</FormLabel>
            <ReactSelect
              isMulti
              options={pincodes}
              value={selectedPincodes}
              onChange={setSelectedPincodes}
              placeholder="Select pincodes"
            />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Status</FormLabel>
            <Input value={status} onChange={(e) => setStatus(e.target.value)} placeholder="Active/Inactive" />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleAdd}>
            Add Route
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddRoute;
