import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  useToast,
} from '@chakra-ui/react';
import { position } from 'stylis';

const EditCategory = ({ isOpen, onClose, updateCategoryProp, updateCategory }) => {
  const [category, setCategory] = useState({
    id: '',
    name: '',
    city: '',
    position:'',
    status: '',
  });

  const toast = useToast();

  useEffect(() => {
    if (updateCategoryProp) {
      setCategory({
        id: updateCategoryProp.id || '',
        name: updateCategoryProp.name || '',
        position: updateCategoryProp.position || '',
        city: updateCategoryProp.city || '',
        status: updateCategoryProp.status || '',
      });
    }
  }, [updateCategoryProp]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCategory({ ...category, [name]: value });
  };

  const handleSubmit = () => {
    if (category.name && category.city && category.status) {
      const formData = {
        id: category.id,
        name: category.name,
        position:category.position,
        city: category.city,
        status: category.status,
      };
      updateCategory(formData);
      onClose();
    } else {
      toast({
        title: 'Error.',
        description: 'Please fill out all fields.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={() => onClose(null)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Category</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Category Name */}
          <FormControl id="name" mb={3}>
            <FormLabel>Category Name</FormLabel>
            <Input
              type="text"
              name="name"
              value={category.name}
              onChange={handleInputChange}
              placeholder="Enter category name"
            />
          </FormControl>

          <FormControl id="position" mb={3}>
            <FormLabel>Position</FormLabel>
            <Input
              type="number"
              name="position"
              value={category.position}
              onChange={handleInputChange}
              placeholder="Enter position"
            />
          </FormControl>

          {/* City Dropdown */}
          <FormControl id="city" mb={3}>
            <FormLabel>City</FormLabel>
            <Select
              name="city"
              value={category.city}
              onChange={handleInputChange}
              placeholder="Select city"
            >
              <option value="Bengaluru">Bengaluru</option>
              <option value="Mysore">Mysore</option>
            </Select>
          </FormControl>

          {/* Status Dropdown */}
          <FormControl id="status" mb={3}>
            <FormLabel>Status</FormLabel>
            <Select
              name="status"
              value={category.status}
              onChange={handleInputChange}
              placeholder="Select status"
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Select>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={() => onClose(null)}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditCategory;
