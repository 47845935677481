import React from "react";
import { Icon } from "@chakra-ui/react";
import {
  MdHome,
  MdPayment,
  MdLocalPharmacy,
  MdList,  // Icon for Categories
  MdAssignment,
  MdHealing,
  MdViewCarousel,  // Icon for Banners
  MdShoppingCart,
  MdPeople,
  MdLocationCity,
  MdDeliveryDining,  // Icon for Cities
} from "react-icons/md";

const SidebarRoutes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/dashboard",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    sidebar: true,
  },
  {
    name: "Orders",
    layout: "/admin",
    path: "/ordermanagement",
    icon: <Icon as={MdLocalPharmacy} width="20px" height="20px" color="inherit" />,
    sidebar: true,
  },
  {
    name: "Subscriptions",  // Subscriptions element
    layout: "/admin",
    path: "/subscriptions",
    icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,  // Icon for Subscriptions
    sidebar: true,
  },
  {
    name: "Transactions",  // Cities section with City and Pincode children
    layout: "/admin",
    path: "/transactions",
    icon: <Icon as={MdLocationCity} width="20px" height="20px" color="inherit" />,
    sidebar: true,
  },
  {
    name: "Banners",  // Banners element
    layout: "/admin",
    path: "/banners",
    icon: <Icon as={MdViewCarousel} width="20px" height="20px" color="inherit" />,
    sidebar: true,
  },
  {
    name: "Categories",  // Categories element
    layout: "/admin",
    path: "/categories",
    icon: <Icon as={MdList} width="20px" height="20px" color="inherit" />,  // Icon for Categories
    sidebar: true,
  },
  {
    name: "Products",  // Products element
    layout: "/admin",
    path: "/products",  // Define the path for Products
    icon: <Icon as={MdShoppingCart} width="20px" height="20px" color="inherit" />,  // Icon for Products
    sidebar: true,
  },
  {
    name: "Customers",  // Customers element
    layout: "/admin",
    path: "/customers",
    icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,  // Icon for Customers
    sidebar: true,
  },
  {
    name: "Pincode",  // Cities section with City and Pincode children
    layout: "/admin",
    path: "/pincode",
    icon: <Icon as={MdLocationCity} width="20px" height="20px" color="inherit" />,
    sidebar: true,
   
  },
  {
    name: "Payment Gateway",  // Cities section with City and Pincode children
    layout: "/admin",
    path: "/paymentgateway",
    icon: <Icon as={MdLocationCity} width="20px" height="20px" color="inherit" />,
    sidebar: true,
  },
  {
    name: "Delivery Boy",  // Cities section with City and Pincode children
    layout: "/admin",
    path: "/deliveryboy",
    icon: <Icon as={MdDeliveryDining} width="20px" height="20px" color="inherit" />,
    sidebar: true,
  },
  {
    name: "Route Managment",  // Cities section with City and Pincode children
    layout: "/admin",
    path: "/routemanagement",
    icon: <Icon as={MdDeliveryDining} width="20px" height="20px" color="inherit" />,
    sidebar: true,
  },
];

const storedUserDataString = sessionStorage.getItem('userData');
let filteredRoutes = SidebarRoutes;

if (storedUserDataString) {
  const storedUserData = JSON.parse(storedUserDataString);

  // Get enabled routes from user data features
  const enabledRoutes = storedUserData.features.map((feature) => feature.label);

  // Filter SidebarRoutes based on enabled routes from user data
  filteredRoutes = SidebarRoutes.filter((route) => {
    return enabledRoutes.includes(route.name);
  }).map((route) => ({
    ...route,
    features: storedUserData.features.find((feature) => feature.label === route.name),
  }));
}

export default filteredRoutes;
