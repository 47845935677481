import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  Textarea,
  useToast,
  RadioGroup,
  Radio,
  Stack,
  HStack,  // Added for horizontal layout
} from '@chakra-ui/react';
import { getCategoriesApi } from 'networks';  // Import the API to fetch categories

const AddProduct = ({ isOpen, onClose, addNewProduct }) => {
  const [newProduct, setNewProduct] = useState({
    productCode: '', 
    name: '',
    position:'',
    category_id: '', 
    price: '',
    city: '',
    cgst: '',
    sgst: '',
    discount: '',
    description: '',
    weight: '',  // New field for weight
    weight_unit: 'gm',  // New field for unit
    subscription: 'Both', 
    status: '', 
  });
  const [categories, setCategories] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);

  const toast = useToast();

  // Fetch categories from the API when component loads
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getCategoriesApi();
        setCategories(response.data.data);
      } catch (error) {
        toast({
          title: 'Error fetching categories.',
          description: 'An error occurred while fetching categories.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    };

    fetchCategories();
  }, [toast]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProduct({ ...newProduct, [name]: value });
  };

  // Handle multiple image file changes
  const handleFileChange = (e) => {
    setImageFiles(e.target.files);
  };

  const handleSubmit = () => {
    if (
      newProduct.productCode &&
      newProduct.name &&
      newProduct.position &&
      newProduct.category_id &&
      newProduct.price &&
      newProduct.city &&
      newProduct.cgst &&
      newProduct.sgst &&
      newProduct.discount &&
      newProduct.description &&
      newProduct.weight &&  // Ensure weight is provided
      newProduct.status &&
      imageFiles.length > 0
    ) {
      const formData = new FormData();
      formData.append('productCode', newProduct.productCode);
      formData.append('name', newProduct.name);
      formData.append('position', newProduct.position);
      formData.append('category_id', newProduct.category_id);
      formData.append('price', newProduct.price);
      formData.append('city', newProduct.city);
      formData.append('cgst', newProduct.cgst);
      formData.append('sgst', newProduct.sgst);
      formData.append('discount', newProduct.discount);
      formData.append('description', newProduct.description);
      formData.append('weight', newProduct.weight);  // Append the new weight field
      formData.append('weight_unit', newProduct.weight_unit);  // Append the weight unit
      formData.append('subscription', newProduct.subscription);
      formData.append('status', newProduct.status);
      
      // Append multiple images
      Array.from(imageFiles).forEach((file) => {
        formData.append('images', file);
      });

      addNewProduct(formData);

      setNewProduct({
        productCode: '',
        name: '',
        position:'',
        category_id: '',
        price: '',
        city: '',
        cgst: '',
        sgst: '',
        discount: '',
        description: '',
        weight: '',  // Reset weight
        weight_unit: 'gm',  // Reset unit
        subscription: 'Both',
        status: '',
      });
      setImageFiles([]);
      onClose();
    } else {
      toast({
        title: 'Error.',
        description: 'Please fill out all fields and upload images.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Product</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Product Code */}
          <FormControl id="productCode" mb={3}>
            <FormLabel>Product Code</FormLabel>
            <Input
              type="text"
              name="productCode"
              value={newProduct.productCode}
              onChange={handleInputChange}
              placeholder="Enter product code"
            />
          </FormControl>

          {/* Product Name */}
          <FormControl id="name" mb={3}>
            <FormLabel>Product Name</FormLabel>
            <Input
              type="text"
              name="name"
              value={newProduct.name}
              onChange={handleInputChange}
              placeholder="Enter product name"
            />
          </FormControl>
          <FormControl id="position" mb={3}>
            <FormLabel>Product Name</FormLabel>
            <Input
              type="number"
              name="position"
              value={newProduct.position}
              onChange={handleInputChange}
              placeholder="Enter product position"
            />
          </FormControl>

          {/* Category */}
          <FormControl id="category_id" mb={3}>
            <FormLabel>Category</FormLabel>
            <Select
              name="category_id"
              value={newProduct.category_id}
              onChange={handleInputChange}
              placeholder="Select category"
            >
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </Select>
          </FormControl>

          {/* Price */}
          <FormControl id="price" mb={3}>
            <FormLabel>Price</FormLabel>
            <Input
              type="number"
              name="price"
              value={newProduct.price}
              onChange={handleInputChange}
              placeholder="Enter price"
            />
          </FormControl>

          {/* City */}
          <FormControl id="city" mb={3}>
            <FormLabel>City</FormLabel>
            <Select
              name="city"
              value={newProduct.city}
              onChange={handleInputChange}
              placeholder="Select city"
            >
              <option value="Bengaluru">Bengaluru</option>
              <option value="Mysore">Mysore</option>
            </Select>
          </FormControl>

          {/* CGST */}
          <FormControl id="cgst" mb={3}>
            <FormLabel>CGST</FormLabel>
            <Input
              type="number"
              name="cgst"
              value={newProduct.cgst}
              onChange={handleInputChange}
              placeholder="Enter CGST percentage"
            />
          </FormControl>

          {/* SGST */}
          <FormControl id="sgst" mb={3}>
            <FormLabel>SGST</FormLabel>
            <Input
              type="number"
              name="sgst"
              value={newProduct.sgst}
              onChange={handleInputChange}
              placeholder="Enter SGST percentage"
            />
          </FormControl>

          {/* Discount */}
          <FormControl id="discount" mb={3}>
            <FormLabel>Discount</FormLabel>
            <Input
              type="number"
              name="discount"
              value={newProduct.discount}
              onChange={handleInputChange}
              placeholder="Enter discount percentage"
            />
          </FormControl>

          {/* Weight with Unit */}
          <FormControl id="weight" mb={3}>
            <FormLabel>Weight</FormLabel>
            <HStack>
              <Input
                type="text"
                name="weight"
                value={newProduct.weight}
                onChange={handleInputChange}
                placeholder="Enter product weight"
              />
              <Select
                name="weight_unit"
                value={newProduct.weight_unit}
                onChange={handleInputChange}
              >
                <option value="gm">gm</option>
                <option value="ml">ml</option>
              </Select>
            </HStack>
          </FormControl>

          {/* Description */}
          <FormControl id="description" mb={3}>
            <FormLabel>Description</FormLabel>
            <Textarea
              name="description"
              value={newProduct.description}
              onChange={handleInputChange}
              placeholder="Enter product description"
            />
          </FormControl>

          {/* Image Upload */}
          <FormControl id="images" mb={3}>
            <FormLabel>Product Images</FormLabel>
            <Input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              multiple
            />
          </FormControl>

          {/* Subscription */}
          <FormControl as="fieldset" mb={3}>
            <FormLabel as="legend">Subscription</FormLabel>
            <RadioGroup
              name="subscription"
              value={newProduct.subscription}
              onChange={(value) => setNewProduct({ ...newProduct, subscription: value })}
            >
              <Stack direction="row">
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
                <Radio value="Both">Both</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>

          {/* Status */}
          <FormControl id="status" mb={3}>
            <FormLabel>Status</FormLabel>
            <Select
              name="status"
              value={newProduct.status}
              onChange={handleInputChange}
              placeholder="Select status"
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Select>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddProduct;
